// SchoolProfile.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { getFieldValue, isFieldValueHidden, getFirstSectionFields } from './utils/profileUtils';
import { updateMetaTags } from './utils/updateMetaTags';
import { useParams } from 'react-router-dom';
import './BaseProfile.css';

const SchoolProfile = () => {
    const { id } = useParams();
    const [schoolProfile, setSchoolProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSchoolProfile = async () => {
            try {
                const docRef = doc(db, "schoolProfiles", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const profileData = docSnap.data();
                    setSchoolProfile(profileData);
                    const name = getFieldValue(profileData.fieldSections, 'Name');
                    const photoURL = getFieldValue(profileData.fieldSections, 'Photo');
                    updateMetaTags({ name, photoUrl: photoURL });
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSchoolProfile();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!schoolProfile) {
        return <div>No profile found</div>;
    }

    const getField = (fieldName) => {
        if (isFieldValueHidden(schoolProfile.fieldSections, fieldName)) {
            return null;
        }
        const field = schoolProfile.fieldSections[0]?.fields.find(f => f.key === fieldName);
        if (!field || !field.value || !field.value.value) return null;

        const value = field.value.value;

        // Handle fields with options
        if (field.options) {
            if (Array.isArray(value)) {
                return value.map(index => field.options[index]).join(', ');
            }
            return field.options[value];
        }

        // Handle complex fields with options in value object
        if (typeof value === 'object' && 'options' in value && 'selected' in value) {
            const selected = Array.isArray(value.selected) ? value.selected : [value.selected];
            return selected.map(index => value.options[index]).join(', ');
        }

        return value;
    };

    const name = getField('Name');
    const photoURL = getField('Photo');
    const city = getField('City');
    const state = getField('State');
    const country = getField('Country');
    const type = getField('Type');
    const gradeLevels = getField('Grade Levels');
    const languages = getField('Languages');

    const explicitlyUsedFields = ['Name', 'Photo', 'City', 'State', 'Country', 'Type', 'Grade Levels', 'Languages'];  // Added 'State'
    const firstSectionFields = getFirstSectionFields(schoolProfile.fieldSections, explicitlyUsedFields);

    const renderFieldValue = (field) => {
        const value = field.value.value;
        if (!value) return null;

        // Handle field values that are objects with options
        if (typeof value === 'object' && !Array.isArray(value)) {
            if ('options' in value && 'selected' in value) {
                const selected = Array.isArray(value.selected) ? value.selected : [value.selected];
                // Map selected indexes to their corresponding option labels
                return selected.map(index => value.options[index]).join(', ');
            }
            return null;
        }

        // Handle arrays of option indexes
        if (Array.isArray(value) && field.options) {
            return value.map(index => field.options[index]).join(', ');
        }

        // Handle single option index
        if (typeof value === 'number' && field.options) {
            return field.options[value];
        }

        // Handle simple values
        return value.toString();
    };

    return (
        <div className="profile-container">
            {photoURL && (
                <div className="profile-picture">
                    <img
                        src={photoURL}
                        alt={name || 'School Profile'}
                    />
                </div>
            )}
            <div className="profile-details">
                {name && <h2>{name}</h2>}
                {(city || state || country) && (
                    <p>{[city, state, country].filter(Boolean).join(', ')}</p>
                )}
                {type && (
                    <section className="profile-section">
                        <h3>SCHOOL TYPE</h3>
                        <p>{type}</p>
                    </section>
                )}
                {(gradeLevels || languages) && (
                    <section className="profile-section">
                        <h3>EDUCATION</h3>
                        {gradeLevels && <p><strong>Grade Levels:</strong> {gradeLevels}</p>}
                        {languages && <p><strong>Languages:</strong> {languages}</p>}
                    </section>
                )}
                {firstSectionFields.length > 0 && (
                    <section className="profile-section">
                        <h3>GENERAL</h3>
                        {firstSectionFields.map(field => (
                            <p key={field.key}>
                                <strong>{field.key.replace(/\s*\([^)]*\)/g, '')}:</strong> {renderFieldValue(field)}
                            </p>
                        ))}
                    </section>
                )}
            </div>
        </div>
    );
};

export default SchoolProfile;