// SchoolProfileJSON.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';

const SchoolProfileJSON = () => {
    const { id } = useParams();
    const [schoolProfile, setSchoolProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSchoolProfile = async () => {
            try {
                const docRef = doc(db, "schoolProfiles", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setSchoolProfile(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSchoolProfile();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!schoolProfile) {
        return <div>No profile found</div>;
    }

    return (
        <div>
            <h1>School Profile</h1>
            <pre>{JSON.stringify(schoolProfile, null, 2)}</pre>
        </div>
    );
};

export default SchoolProfileJSON;